import React from 'react';
import { Modal } from 'antd';

const PlatformAlertModal = ({ visible, onClose }) => {
  return (
    <Modal open={!!visible} closable={false} footer={null} centered>
      <div style={{ fontFamily: 'Open sans' }} className="d-flex flex-column align-items-center">
        <div className="my-4">
          <img src="/assets/images/icons/sweet-delete.svg" alt="delete" />
        </div>
        <p className="mb-2 text-center fs-16 fw-600">Oops! We're not mobile-ready yet.</p>
        <p className="text-center fs-14">For the best experience, please access it on your desktop!</p>
        <div className="w-100 flex-wrap d-flex align-items-center justify-content-between">
          <div
            className="cursor-pointer fw-600 bg-white rounded-3 border border-2 border-dark px-3 py-2 rounded-pill text-black"
            onClick={() => window.open('https://www.kelick.io/get-a-demo', '_self')}
          >
            Contact Us
          </div>
          <div className="cursor-pointer fw-600 bg-black rounded-pill  px-4 py-2 text-white" onClick={onClose}>
            Continue Anyway
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default PlatformAlertModal;
