import { lazy } from 'react';

const AuthGoogleRedirect = lazy(() => import('./AuthGoogleRedirect'));
const AuthGoogleInvite = lazy(() => import('./AuthGoogleInvite'));
const Error = lazy(() => import('./Error'));
const CreateNewPassword = lazy(() => import('./CreateNewPassword'));
const authRoutes = [
  {
    path: '/auth',
    component: AuthGoogleRedirect,
  },
  {
    path: '/auth-invite-google',
    component: AuthGoogleInvite,
  },
  {
    path: '/login-google-fail',
    component: Error,
  },
  {
    path: '/createNewPassword',
    component: CreateNewPassword,
  },
];

export default authRoutes;
