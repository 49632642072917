import DatePickerBase from 'react-datetime';
import React from 'react';

const DatePicker = (props) => {
  return (
    <div className={`keclick-date-picker-container ${props.classWrap || ''}`}>
      <div className="date-input">
        <DatePickerBase
          {...props}
          selected={props?.value}
          timeFormat={false}
          input={!props.isHideInput}
          inputProps={{ readOnly: true, placeholder: props?.placeholder || '--/--/----', disabled: props?.disabled }}
          dateFormat={props.dateFormat || 'MM/DD/YYYY'}
        />
      </div>
      {!props?.hideIcon && <div className="icon">
        <img src="/assets/images/customDatePicker/calendar.svg" alt="calendar" />
      </div>
      }
    </div>
  );
};

export default DatePicker;
