export const navigations = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: 'dashboard',
    module: 'dashboard',
    iconType: 'custom',
  },
  {
    name: 'Notification',
    path: '/notification',
    icon: 'notification',
    module: 'notification',
    iconType: 'custom',
  },
  {
    name: 'Organization',
    path: '/organization',
    icon: 'organization',
    module: 'organization',
    iconType: 'custom',
  },
  {
    name: 'Interview',
    path: '/interview',
    icon: 'interview',
    module: 'interview',
    iconType: 'custom',
  },
  {
    name: 'Employees',
    path: '/employees',
    icon: 'employee',
    module: 'employee',
    iconType: 'custom',
  },
  {
    name: 'Payroll',
    path: '/payroll',
    icon: 'payroll',
    module: 'payroll',
    iconType: 'custom',
  },
  {
    name: 'Asset',
    path: '/assets',
    icon: 'asset',
    module: 'asset',
    iconType: 'custom',
  },
  {
    name: 'Attendance',
    path: '/attendance',
    icon: 'attendance',
    module: 'attendance',
    iconType: 'custom',
  },
  {
    name: 'Shift Planning',
    path: '/shift-planning',
    icon: 'shift-planning',
    module: 'shift',
    iconType: 'custom',
  },
  {
    name: 'Claims',
    path: '/claims',
    icon: 'claims',
    module: 'claims',
    iconType: 'custom',
  },
  {
    name: 'Leaves',
    path: '/leaves',
    icon: 'leaves',
    module: 'leaves',
    iconType: 'custom',
  },
  {
    name: 'Appraisal',
    path: '/appraisal',
    icon: 'appraisal',
    module: 'appraisal',
    iconType: 'custom',
  },
  {
    name: 'Desk Booking',
    path: '/desk-booking',
    icon: 'desk-booking',
    module: 'desk_booking',
    iconType: 'custom',
  },
  {
    name: 'Room Booking',
    path: '/room-booking',
    icon: 'room-booking',
    module: 'room_booking',
    iconType: 'custom',
  },
  {
    name: 'Chat',
    path: '/chat',
    icon: 'chat',
    module: 'chat',
    iconType: 'custom',
  },
  {
    name: 'Plans & Billing',
    path: '/plan',
    icon: 'plans',
    module: 'plan',
    iconType: 'custom',
  },
];

export const superAdmin_Navigation = [
  {
    name: 'Dashboard',
    path: '/sa-dashboard',
    icon: 'dashboard',
    iconType: 'custom',
  },
  {
    name: 'Activity Log',
    path: '/activity-log',
    icon: 'activityLog',
    iconType: 'custom',
  },
  {
    name: 'Super Admins',
    path: '/manage-super-admin',
    icon: 'superAdmins',
    iconType: 'custom',
  },
  {
    name: 'Plans',
    path: '/plan',
    icon: 'plans',
    iconType: 'custom',
  },
  {
    name: 'Manage Admins',
    path: '/manage-admin',
    icon: 'manageAdmins',
    iconType: 'custom',
  },
  {
    name: 'Manage Entities',
    path: '/manage-entities',
    icon: 'manageEntities',
    iconType: 'custom',
  },
  {
    name: 'Finance',
    path: '/finance',
    icon: 'finance',
    iconType: 'custom',
  },
  {
    name: 'Support',
    path: '/support',
    icon: 'support',
    iconType: 'custom',
  }
];

export const defaultCommonRoute =  [
  '/staff-notification',
  '/staff-dashboard',
  '/dashboard',
  '/notification',
  '/employees',
  // '/interview',
  '/support',
  '/support/create',
  '/plan',
  '/chat',
  '/pages/user-profile',
  '/pages/user-profile/change-password',
  '/pages/employee-profile',
  '/pages/employee-change-password',
  '/pages/switch-employee-profile',
]